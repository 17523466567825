/**
 * Temporary component to ease transition to the new Bynder Image format.
 * Remove it and replace with regular HeroImage after rewriting SchoolPage to use BynderPicture
 *
 */
import { forwardRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import cx from 'classnames'
import Image from 'next/image'

import withScroll from 'common/utils/withScroll'
import getImgFromBynder from 'common/utils/getImgFromBynder'
import { Container, SchoolFilters, Typography, GradientBlock } from 'common/widgets'
import { ImageType } from '../types'
import classNames from './styles.module.scss'
import { connect } from 'react-redux'
import { template } from 'lodash'
import parseTemplateData from 'common/utils/parseTemplateData'


const propTypes = {
  data: PropTypes.shape({
    pretitle: PropTypes.string,
    pretitle_font_size: PropTypes.oneOf(['normal', 'large', 'small']),
    title: PropTypes.string,
    title_font_size: PropTypes.oneOf(['normal', 'large', 'small']),
    subtitle: PropTypes.string,
    subtitle_font_size: PropTypes.oneOf(['normal', 'large', 'small']),
    filter_caption: PropTypes.string,
    button_caption: PropTypes.string,
    filter_caption_font_size: PropTypes.string,
    filtersHeading: PropTypes.string,
    image: ImageType.isRequired,
  }),
  type: PropTypes.string,
}

const defaultProps = {
  data: {},
  type: undefined,
}

const HeroImage = forwardRef(({ data, type }, ref) => {
  const imgSrc = useMemo(() => getImgFromBynder(get(data, 'image.bynder'), ['header', 'webbig', 'webImage']), [data?.image?.bynder])
  return (
    <div ref={ref} className={classNames.wrapper}>
      <Image
        src={imgSrc}
        alt={get(data, 'image.data.alttext')}
        title={get(data, 'image.data.name')}
        layout="fill"
        objectFit="cover"
        priority
      />
      <GradientBlock />
      <Container size="xl">
        <Typography variant="h1" color="white" className={classNames.headingContainer}>
          {data.pretitle && (
            <span className={cx(classNames.heading, classNames[data.pretitle_font_size])}>{data.pretitle + ' '}</span>
          )}
          {data.title && (
            <span className={cx(classNames.heading, classNames[data.title_font_size])}>{data.title + ' '}</span>
          )}
          {data.subtitle && (
            <span className={cx(classNames.heading, classNames[data.subtitle_font_size])}>{data.subtitle + ' '}</span>
          )}
        </Typography>
        {type === 'home_page_hero_image_with_quick_filter' && (
          <div className={classNames.filters}>
            <SchoolFilters
              heading={data.filter_caption}
              headingFontSize={data.filter_caption_font_size}
              btnCaption={data.button_caption}
            />
          </div>
        )}
      </Container>
    </div>
  )
})

HeroImage.propTypes = propTypes
HeroImage.defaultProps = defaultProps

const HeroWithScroll = withScroll(HeroImage, classNames.active)

export default connect((state, { pageType, seoContext, data, languageCode }) => {
  const seoTemplates = get(state, 'cmsInfo.data.seo_templates', {})[pageType] || {}
  const titleTemplate = seoTemplates[`h1_title_${languageCode}`]
  const pretitleTemplate = seoTemplates[`h1_pretitle_${languageCode}`]
  const subtitleTemplate = seoTemplates[`h1_subtitle_${languageCode}`]
  return {
    data: {
      ...data,
      title: template(parseTemplateData(titleTemplate, seoContext))(seoContext),
      subtitle: template(parseTemplateData(subtitleTemplate, seoContext))(seoContext),
      pretitle: template(parseTemplateData(pretitleTemplate, seoContext))(seoContext),
      pretitle_font_size: seoTemplates.h1_pretitle_font_size,
      title_font_size: seoTemplates.h1_title_font_size,
      subtitle_font_size: seoTemplates.h1_subtitle_font_size,
    },
  }
})(HeroWithScroll)
